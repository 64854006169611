import React from 'react'
import fetch from 'isomorphic-fetch'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import './src/styles/style.scss'


const cache = new InMemoryCache()

const transitionDelay = 0

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const wrapRootElement = ({ element }) => {
    const client = new ApolloClient({
      cache: cache,
      link: new HttpLink({
        uri: `${process.env.API_URL}/wp/graphql`,
      })
    })
    return <ApolloProvider client={client}>{element}</ApolloProvider>;
}

