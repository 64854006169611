// import PropTypes from 'prop-types';
import React from 'react'

import Header from './partials/header'
import Footer from './partials/footer'
import Transition from './transition'

import 'typeface-roboto'

const Layout = props => {
  	return (
	    <>
	      <Header />
	      <main className='main-content'>
                <Transition location={props.location}>
                  { props.children }
                  <Footer />
                </Transition>
	      </main>
    	</>
  	);
}

export default Layout;
