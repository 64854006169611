import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'

import parse from 'html-react-parser'
import map from 'lodash/map'


import Link from '../../../components/link/link'
import NewsletterForm from '../../../components/newsletter-form/newsletter-form'

const Footer = () => {
  const { wp: { general, footer } } = useStaticQuery(graphql`
    query footerGeneralQuery {
      wp {
        general {
          settingsGeneral {
            address
            email
            phone
            newsletterForm {
              ... on WpContactForm {
                formId: databaseId
              }
            }
          }
        }
        footer {
          settingsFooter {
            menusFooter {
              title
              links {
                link {
                  url
                  title
                  target
                }
              }
            }
            newsletter
          }
        }
      }
    }
  `)

  return (

    <footer className="footer-content overflow-hidden relative bg-gray-dark">
      <div className="py-6 sm:py-10 lg:py-16 xl:py-20 container-max container mx-auto">
          <div className="flex flex-wrap -mx-6 justify-between">
            <div className="w-full lg:w-1/2 xl:w-1/2 lg:px-6 xl:pr-12">
                <div className="bordered-yellow flex flex-wrap justify-center lg:justify-start items-center pb-5 lg:pb-8">
                  { general.settingsGeneral.phone &&
                    <a className="w-full mb-3 md:mb-0 md:w-auto justify-center md:justify-start text-white text-lg xl:text-xl inline-flex items-center md:mr-12 xl:mr-16" href={`tel:${general.settingsGeneral.phone.replace(/\s/g,'')}`}>
                      <svg className="fill-current w-4 h-4 mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15.285,12.305l-2.578-2.594c-0.39-0.393-1.025-0.393-1.416-0.002L9,12L4,7l2.294-2.294 c0.39-0.39,0.391-1.023,0.001-1.414l-2.58-2.584C3.324,0.317,2.691,0.317,2.3,0.708L0.004,3.003L0,3c0,7.18,5.82,13,13,13 l2.283-2.283C15.673,13.327,15.674,12.696,15.285,12.305z"></path></svg>
                      <span>{parse(general.settingsGeneral.phone)}</span>
                    </a>
                  }
                  { general.settingsGeneral.email &&
                    <a className="w-full mb-3 md:mb-0 md:w-auto justify-center md:justify-start text-white text-lg xl:text-xl inline-flex items-center" href={`mailto:${general.settingsGeneral.email.replace(/\s/g,'')}`}>
                      <svg className="fill-current w-4 h-4 mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M33.933,37.5a4.006,4.006,0,0,1-3.867,0L2,22.017V51a6.006,6.006,0,0,0,6,6H56a6.006,6.006,0,0,0,6-6V22.017Z"></path><path d="M56,7H8a6.006,6.006,0,0,0-6,6v5a1,1,0,0,0,.517.876l29,16a1,1,0,0,0,.966,0l29-16A1,1,0,0,0,62,18V13A6.006,6.006,0,0,0,56,7Z"></path></svg>
                      <span>{parse(general.settingsGeneral.email)}</span>
                    </a>
                  }
                </div>

                <div className="flex flex-wrap pt-6 xl:pt-8 -mx-4">
                    {
                      map(footer.settingsFooter.menusFooter, (item, key) => (
                        <div className="w-full lg:w-1/2 xl:w-1/4 px-4 mb-5" key={key}>
                          { item.title &&
                            <h4 className="font-light text-center lg:text-left lg:mb-3 xl:mb-4 text-xs text-white uppercase tracking-wider">{ item.title }</h4>
                          }
                          { item.links &&
                            <ul className="list-none text-center lg:text-left">
                              { map(item.links, (child, keychild) => (
                                  <li className="my-1" key={keychild}><Link to={child.link.url} target={child.link.target ? child.link.target : '_self'} className="text-sm block text-white hover:text-yellow">{ child.link.title }</Link></li>
                                ))
                              }
                            </ul>
                          }

                        </div>
                      ))
                    }
                    { general.settingsGeneral.address &&
                      <div className="w-full xl:w-1/2 px-4">
                        <h4 className="font-light text-xs text-white uppercase tracking-wider text-center lg:text-left">Location</h4>
                        <div className="text-sm md:text-base text-white text-center lg:text-left">{ parse(general.settingsGeneral.address.replace(/(?:\r\n|\r|\n)/g, '<br />')) }</div>
                      </div>
                    }

                </div>


            </div>
            {
              general.settingsGeneral.newsletterForm && general.settingsGeneral.newsletterForm.formId &&
              <NewsletterForm disclaimer={footer.settingsFooter.newsletter} id={general.settingsGeneral.newsletterForm.formId} />
            }

          </div>
      </div>
      <div className="footer-content__credits py-3 sm:py-6 bg-gray-normal">
        <div className="container container-max mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="w-full sm:w-auto text-center sm:text-left text-xs text-white">Laver Leisure. { new Date().getFullYear() } &copy; All rights reserved</div>
            <div className="w-full sm:w-auto text-center sm:text-right text-xs text-white">Site by <a href="https://vital.agency/" target="_blank">Vital Agency</a></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
