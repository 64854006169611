import React from 'react'
import { Link as LinkAs } from 'gatsby'
// import { gsap } from 'gsap'

// const interestingExitAnimation = ( exit, node ) => {
// 	gsap.to(node, exit.length, { y: '-30px', opacity: 0, display: 'none', ease: "power2.out" })
// }

// const interestingEntryAnimation = ( entry, node ) => {
// 	gsap.set(node, { opacity: 0, y: '70px', visibility: 'visible' })
// 	gsap.to(node, entry.length, { y: '0px', opacity: 1, ease: "power2.out" })
// }

const Link = props => {
	if(props.target === '_blank') {
		return (
			<a { ...props} href={ props.to ? props.to : '' } target="_blank" rel="noopener noreferrer">
				{ props.children }
			</a>
		)
	}
  	return (
	    <LinkAs { ...props}
	    	to={ props.to ? props.to : '' }
	    >
	        { props.children }
	    </LinkAs>
  	);
}



export default Link;
