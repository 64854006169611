import { graphql, StaticQuery } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import parse from 'html-react-parser'
import map from 'lodash/map'
import { gsap } from 'gsap'

import Link from '../../../components/link/link'
import logo from '../../../images/logo-website.svg'

const Header = ({ data }) => {
  const [ mobileopen, setMobileOpen ] = useState(false)
  const upperRef = useRef(false)
  const middleRef = useRef(false)
  const lowerRef = useRef(false)

  const toggleMobileMenu = () => {
    if(!mobileopen){
        const tl = gsap.timeline({  onComplete: () => setMobileOpen(true) })
        tl
          .to(upperRef.current, 0.2, {attr: {d: "M8,2 L2,8"}, x: 1, ease:'power2.out'}, 'start')
          .to(middleRef.current, 0.2, {autoAlpha: 0}, 'start')
          .to(lowerRef.current, 0.2, {attr: {d: "M8,8 L2,2"}, x: 1, ease:'power2.out'}, 'start')
    } else {
        const tl = gsap.timeline({ onComplete: () => setMobileOpen(false) })
        tl
          .to(upperRef.current, 0.2, {attr: {d: "M10,2 L2,2"}, x: 0, ease:'power2.out'}, 'start')
          .to(middleRef.current, 0.2, { attr: { d: "M2,5 L10,5"}, autoAlpha: 1, ease:'power2.out'}, 'start')
          .to(lowerRef.current, 0.2, {attr: {d: "M10,8 L2,8"}, x: 0, ease:'power2.out'}, 'start')
        }

  }
  return(
      <header className={`header-app bg-white fixed top-0 left-0 right-0 z-20`}>
        <div className={`header-app__inner container container-max mx-auto flex items-stretch justify-end lg:justify-between`}>
            <Link className='header-app__logo flex items-center mr-auto lg:mr-0' to='/'>
                <img
                  alt={ `Laver Leisure` }
                  src={ logo }
                />
            </Link>

            { data.wp &&
            <nav className='nav-primary hidden flex-grow lg:flex items-stretch justify-center bg-white'>
              {
                data.wp.general.settingsGeneral.mainMenu.map(({ link }) => (
                    <Link
                      key={link.title}
                      to={ link.url }
                      target={ link.target }
                      activeClassName='active'
                      className='flex items-center text-black menu-item relative px-4 xl:mx-2 h-full block'
                    >
                      <span className='menu-item__text relative'>
                        <span className="relative z-10">{ parse(link.title) }</span>
                        <span className="menu-item__text__bg"></span>
                      </span>

                    </Link>
                ))}
            </nav>
            }


            <nav className='nav-secondary bg-black flex-grow-0 flex items-stretch'>
              <div className="hidden lg:flex items-stretch">
                <div className="cursor-pointer text-white flex items-center lg:px-8 xl:px-12 bg-pink nav-secondary__more">
                  <span>More</span>
                  <svg className="ml-4 w-4 h-4 fill-current stroke-current text-white" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <g strokeLinecap="square" strokeWidth="4" transform="translate(0.5 0.5)">
                      <polyline fill="none" points="45,17 24,31 3,17" />
                    </g>
                  </svg>
                </div>
                {
                  data.wp.general.settingsGeneral.moreMenu.length &&
                  <div className="list-reset megamenu megamenu--more">
                    <ul className="list-none px-0 megamenu__container lg:mx-auto lg:max-w-6xl justify-center lg:justify-around lg:flex">
                      {
                        data.wp.general.settingsGeneral.moreMenu.map(({ link }) => (
                            <li key={link.title}>
                                    <Link to={link.url} activeClassName='active' target={link.target} className="menu-item text-xl flex items-center px-6 md:px-10 py-2 lg:px-0 lg:py-2">
                                      <span className="relative z-10">{ parse(link.title) }</span>
                                      <span className="menu-item__text__bg"></span>
                                    </Link>

                            </li>
                        ))
                      }



                    </ul>
                  </div>
                }

              </div>
              {
                data.wp.general.settingsGeneral.phone &&
                <a href={`tel:${data.wp.general.settingsGeneral.phone}`} className="flex items-center px-6 sm:px-6 xl:px-8">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 xl:w-6 xl:h-6 fill-current stroke-current text-white" viewBox="0 0 24 24"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" transform="translate(0.5 0.5)"><path d="M14.308,15.191,12.167,17.3a17.411,17.411,0,0,1-3.092-2.376A17.607,17.607,0,0,1,6.7,11.834L8.81,9.693a.918.918,0,0,0,.189-1L6.8,3.56a.918.918,0,0,0-1.078-.525L1.688,4.1A.919.919,0,0,0,1,5.019,19.4,19.4,0,0,0,6.49,17.511,19.4,19.4,0,0,0,18.986,23a.917.917,0,0,0,.917-.688l1.066-4.036a.917.917,0,0,0-.524-1.078L15.316,15A.917.917,0,0,0,14.308,15.191Z" fill="none" strokeMiterlimit="10"></path><path d="M14,1a9,9,0,0,1,9,9" fill="none" strokeMiterlimit="10"></path><path d="M14,5a5,5,0,0,1,5,5" fill="none" strokeMiterlimit="10"></path></g></svg>

                </a>
              }
            </nav>
            { mobileopen &&
              <div className="mobile-menu">
                <div className="container">
                  <ul className="list-none">
                    {
                      data.wp.general.settingsGeneral.mainMenu.map(({ link }) => (
                          <li key={ link.title } className="mb-1 px-0">
                            <Link onClick={toggleMobileMenu} to={ link.url } target={ link.target } activeClassName="active" className="menu-item">
                              <span className="menu-item__text relative">
                                <span className="relative z-10">{ parse(link.title) }</span>
                                <span className="menu-item__text__bg"></span>
                              </span>
                            </Link>
                          </li>

                      ))
                    }
                  </ul>
                  <ul className="list-none mt-3">
                    {
                      data.wp.general.settingsGeneral.moreMenu.map(({ link }) => (
                          <li key={ link.title } className="mb-1 px-0">
                            <Link onClick={toggleMobileMenu} to={link.url} activeClassName='active' target={link.target} className="menu-item menu-item--secondary">
                              <span className="relative z-10">{ parse(link.title) }</span>
                              <span className="menu-item__text__bg"></span>
                            </Link>
                          </li>
                      ))
                    }


                  </ul>

                </div>
              </div>
            }
            <button type="button" onClick={ toggleMobileMenu } className="flex items-center justify-center lg:hidden px-5 sm:px-6 bg-pink hamburger">
              <svg viewBox="0 0 12 10" className="w-6 h-6">
                <path d="M10,2 L2,2" ref={upperRef} fill="none" strokeLinecap="round" />
                <path d="M2,5 L10,5" ref={middleRef} fill="none" strokeLinecap="round" />
                <path d="M10,8 L2,8" ref={lowerRef} fill="none" strokeLinecap="round" />
              </svg>
            </button>
        </div>

      </header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query headerGeneralQuery {
        wp {
          general {
            settingsGeneral {
              phone
              mainMenu {
                link {
                  target
                  title
                  url
                }
              }
              moreMenu {
                link {
                  url
                  title
                  target
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
