exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-owners-area-tsx": () => import("./../../../src/pages/owners-area.tsx" /* webpackChunkName: "component---src-pages-owners-area-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-faqs-tsx": () => import("./../../../src/templates/faqs.tsx" /* webpackChunkName: "component---src-templates-faqs-tsx" */),
  "component---src-templates-holiday-park-single-tsx": () => import("./../../../src/templates/holidayParkSingle.tsx" /* webpackChunkName: "component---src-templates-holiday-park-single-tsx" */),
  "component---src-templates-holiday-parks-index-tsx": () => import("./../../../src/templates/holidayParksIndex.tsx" /* webpackChunkName: "component---src-templates-holiday-parks-index-tsx" */),
  "component---src-templates-home-single-tsx": () => import("./../../../src/templates/homeSingle.tsx" /* webpackChunkName: "component---src-templates-home-single-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-homes-index-tsx": () => import("./../../../src/templates/homesIndex.tsx" /* webpackChunkName: "component---src-templates-homes-index-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/newsSingle.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

