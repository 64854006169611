import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import parse from 'html-react-parser'

import Loader from '../../components/loader/loader'


const NewsletterForm = ({id, disclaimer}) => {
    if(!id) {
        return <></>
    }
    const [ errors, setErrors ] = useState([])
    // const [ token, setToken] = useState('')
    // const [tokenCaptcha, setTokenCaptcha] = useState(null)
    const [ loading, setLoading] = useState(false)
    const [ isSuccessMessage, setIsSuccessMessage] = useState(false)
    const [ messageSent, setMessageSent] = useState(false)

    // useEffect(() => {
    //     const script = document.createElement("script")
    //     script.src = "https://www.google.com/recaptcha/api.js"
    //     script.async = true
    //     script.defer = true
    //     document.body.appendChild(script)
    // }, [])

    // useEffect(() => {
    //     if(id){
    //         setLoading(true)
    //         axios.post(`${process.env.API_URL}/wp-json/jwt-auth/v1/token`, {
    //             username: 'gatsbysite',
    //             password: 'CNDVJ3TfL6)$k4qHYdp4^EJJ',
    //         }).then(({data}) => {
    //             setLoading(false)
    //             setToken(data.data.token)
    //         }).catch(error => console.error( 'Error', error ))
    //     }
    // }, [id])

    const {
        handleChange,
        isSubmitting,
        values,
        handleSubmit,
    } = useFormik({
        initialValues: {
          namenews: '',
          emainews: '',

        },
        onSubmit: ({
          namenews,
          emailnews,
        }, { setSubmitting, resetForm }) => {
            // if (tokenCaptcha !== null) {
                setLoading(true)
                setSubmitting(true)
                setMessageSent(false)
                setIsSuccessMessage(false)

                const bodyFormData = new FormData();
                bodyFormData.set('namenews', namenews);
                bodyFormData.set('emailnews', emailnews);
                axios.post(`${process.env.API_URL}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`, bodyFormData, {
                    headers: {
                      // 'Authorization': `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if(response.data.status === 'mail_sent'){
                        resetForm()
                        setLoading(false)
                        setSubmitting(false)
                        setMessageSent(true)
                        setIsSuccessMessage(true)
                    }
                    else if(response.data.status === 'validation_failed') {
                        map(response.data.invalid_fields, item => {
                            const prevErr = errors
                            prevErr[item.idref] = item.message
                            setErrors(prevErr)
                        })
                        setLoading(false)
                        setSubmitting(false)
                    }
                    else {
                        setLoading(false)
                        setSubmitting(false)
                        setMessageSent(true)
                        setIsSuccessMessage(false)
                    }
                }).catch(error => {
                    setLoading(false)
                    setSubmitting(false)
                    setMessageSent(true)
                    setIsSuccessMessage(false)
                })

            // }
        },
    })

    useEffect(() => {
        setTimeout(() => {
            setMessageSent(false)
            setIsSuccessMessage(false)
        }, 5000);
    }, [isSuccessMessage, messageSent])

    return (
            <div className="w-full lg:w-1/2 px-6 lg:px-6 xl:flex-1 lg:flex lg:flex-col mt-5 lg:mt-0 lg:justify-end">
              <div className="relative newsletter-box w-full lg:max-w-lg p-6 md:p-10 mb-auto lg:ml-auto">
                <h3 className="block-title">Sign up to our Newsletter</h3>
                <form onSubmit={handleSubmit}>
                    {
                        loading &&
                        <Loader light={true} bgLight={false} />
                    }

                      <div className="flex flex-wrap md:flex-no-wrap mb-6">
                        <input onChange={handleChange} value={values.namenews} type="text" name="namenews" id="namenews" placeholder="Your name" className="input w-full text-white md:flex-1 cursor-pointer bg-transparent relative h-12 appearance-none rounded-none p-0 px-4 outline-none text-xs" required />
                        <input onChange={handleChange} value={values.emailnews} type="email" name="emailnews" id="emailnews" placeholder="Your email" className="email-cont input text-white flex-1 cursor-pointer bg-transparent relative h-12 appearance-none rounded-none p-0 px-4 outline-none text-xs" required />

                        <button type="submit" className="button-submit relative flex items-center justify-center appearance-none w-12 h-12 bg-yellow">
                          <svg className="text-black stroke-current fill-current w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><title>Submit</title><g strokeWidth="1"><polyline points="11.5 4.5 0.5 4.5 0.5 11.5" fill="none" strokeLinecap="round" strokeLinejoin="round" data-color="color-2"></polyline> <polyline points="8 8 11.5 4.5 8 1" fill="none" strokeLinecap="round" strokeLinejoin="round"></polyline></g></svg>
                        </button>

                      </div>
                        { isSuccessMessage && messageSent && (
                            <div className="bg-yellow text-xs mb-2 rounded text-black text-center px-3 py-2">Sign up successfully!</div>
                        )}

                        { !isSuccessMessage && messageSent && (
                            <div className="bg-pink text-xs mb-2 rounded text-white text-center px-3 py-2">There is been an error. Please try again.</div>
                        )}

                </form>
                <div className="newsletter-box__disclaimer font-light text-xs text-white">
                  { disclaimer &&
                    parse(disclaimer)
                  }
                </div>
              </div>
            </div>
    )

}
export default NewsletterForm
